<script lang="ts" setup>
import IMG_WITH_LABEL from '@assets/images/branding/vocal-identity-label-logo.svg'
import IMG_ONLY_LOGO from '@assets/images/branding/vocal-identity-logo.svg'

type Numberish = string | number

const props = defineProps<{
  logoOnly?: boolean

  size?: Numberish
  width?: Numberish
  height?: Numberish
}>()

const imageSrc = props.logoOnly ? IMG_ONLY_LOGO : IMG_WITH_LABEL
</script>

<template>
  <div
    v-once
    :class="$attrs.class || 'bg-primary-500'"
    :style="{
      width: `${size || width}px`,
      height: `${size || height}px`,
      maskImage: `url(${imageSrc})`,
      maskSize: '100% 100%',
      aspectRatio: logoOnly ? '1 / 1' : '71.5 / 24',
    }"
  />
</template>
